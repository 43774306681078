import React, {useEffect, useContext} from 'react'
import {ScrollContext} from '../../../root/layout/Layout';

export default function TopScroll({content}) {

    const scrollTop = useContext(ScrollContext);

    useEffect(()=>{
        scrollTop(0);
    }, [])
    
    return (
        <>{content}</>
    )
}
