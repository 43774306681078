import React, {useRef, useState, useEffect} from 'react';
import Responsive from 'react-responsive-window';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Router } from 'react-router-dom';
import history from '../../libs/history';
import {ProvideHeaderLoading} from '../../components/internal/containers/headers/useHeaderLoading'
import Header from '../../components/internal/containers/headers/Header'
import DropMenu from '../../components/internal/containers/headers/DropMenu'
import Footer from '../../components/internal/containers/footer/Footer'
import Routes from '../routes/Routes';
import {useScroll} from '../../global-hooks/useScroll';
import 'react-perfect-scrollbar/dist/css/styles.css'
import './Layout.scss';

export const ScrollContext = React.createContext(null);

function Layout() {

    const headerEl = useRef(null);
    const [contentMargin, setContentMargin] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);
    const scroll = useScroll();

    useEffect(()=>{
        let newContentHeight = window.innerHeight - headerEl.current.firstChild.clientHeight;
        if (newContentHeight !== contentHeight) {
            setContentHeight(newContentHeight);
            setContentMargin(headerEl.current.firstChild.clientHeight);
        }
    }, [headerEl, contentHeight])

    useEffect(() => {
        if(scroll.isScroll){
            scroll.setScrollY(0);
        }
    }, [scroll.isScroll])

    const scrollTop = (location) => {
        const container = document.querySelector('.layout-main-container');
        container.scrollTop = location;
    }


    return (
        <ScrollContext.Provider value={scrollTop}>
            <Responsive mobileXl={ 766 } desktopLg={2000} desktopMd={1400}>

                <Router history={history}>

                    <div id="nvbr" ref={headerEl}>
                        <ProvideHeaderLoading>
                            <Header/>
                        </ProvideHeaderLoading>
                    </div>

                    <DropMenu/>

                    <PerfectScrollbar className="layout-main-container" component="div" style={{height: contentHeight + 'px', top: contentMargin + 'px'}}>
                        <Routes/>
                        <Footer/>
                    </PerfectScrollbar>
                </Router>

            </Responsive>
        </ScrollContext.Provider>
    );
}

export default Layout;