import React, {Suspense, lazy, useContext} from 'react'
import HomePage from '../../components/pages/home-page/HomePage'
import { Route, Switch, Redirect } from 'react-router-dom'
import LoadingComponent from '../../components/internal/generic/loading/LoadingComponent';
import {ManifestContext} from '../entry/App';
import {ProvideAdminPageLoading} from '../../components/pages/admin-page/useAdminPageLoading'
import {ProvideGenericLoading} from '../../components/pages/generic-page/useGenericLoading'
import {ProvideLoginLoading} from '../../components/pages/login-page/useLoginLoading'

const GenericPage = lazy(() =>  import('../../components/pages/generic-page/GenericPage'));
const LoginPage = lazy(() =>  import('../../components/pages/login-page/LoginPage'));
const AuthGeneralPage = lazy(() =>  import('../../components/pages/auth-general-page/AuthGeneralPage'));
const AuthAdminPage = lazy(() =>  import('../../components/pages/auth-admin-page/AuthAdminPage'));

export default function Routes(props) {

    let home;
    let routes = [];
    const [data, setData] = useContext(ManifestContext);

    if(data){

        let manifestRoutes = data.routes;

        home = <Route exact path="/" render={props => (
            <HomePage manifest={manifestRoutes[0]} data={data.homePage}/>
        )}/>

        for(let i = 0; i < manifestRoutes.length; i++){
    
            if (manifestRoutes[i].pageType === 'gen-page') {

                routes.push(
                    <Route key={i} exact path={"/"+manifestRoutes[i].urlString} render={props => (
                        <Suspense fallback={<LoadingComponent isContextControlled={false} context={false}/>}>
                            <ProvideGenericLoading>
                                <GenericPage manifest={manifestRoutes[i]} urlProps={props}/>
                            </ProvideGenericLoading>
                        </Suspense>
                    )}/>
                )
    
            } else if (manifestRoutes[i].pageType === 'admin-page') {
    
                routes.push(
                    <Route exact key={i} path={"/"+manifestRoutes[i].urlString} render={props => (
                        <Suspense fallback={<LoadingComponent isContextControlled={false} context={false}/>}>
                            <ProvideAdminPageLoading>
                                <AuthAdminPage manifest={manifestRoutes[i]}/>
                            </ProvideAdminPageLoading>
                        </Suspense>
                    )}/>
                )
    
            } else if (manifestRoutes[i].pageType === 'login-page') {

                routes.push(
                    <Route exact key={i} path={"/"+manifestRoutes[i].urlString} render={props => (
                        <Suspense fallback={<LoadingComponent isContextControlled={false} context={false}/>}>
                            <ProvideLoginLoading>
                                <LoginPage manifest={manifestRoutes[i]}/>
                            </ProvideLoginLoading>
                        </Suspense>
                    )}/>
                )
    
            } else if (manifestRoutes[i].pageType === 'auth-general-page') {
    
                routes.push(
                    <Route exact key={i} path={"/"+manifestRoutes[i].urlString} render={props => (
                        <Suspense fallback={<LoadingComponent isContextControlled={false} context={false}/>}>
                            <ProvideGenericLoading>
                                <AuthGeneralPage manifest={manifestRoutes[i]}/>
                            </ProvideGenericLoading>
                        </Suspense>
                    )}/>
                )
    
            }
        }
    
    }

    return (
        <Switch>
            
            {home}

            {routes.map((route) =>(
                route
            ))}

            <Redirect to="/" />

        </Switch>
    )

}
