export const increaseArrayByPush = (array, targetNumber, pushObj) => {
    let newArray = [...array];
    for(let i = array.length; i < targetNumber; i++){
        newArray.push(pushObj);
    }
    return newArray;
}

export const decreaseArrayByPop = (array, targetNumber) => {
    let newArray = [...array];
    for(let i = array.length; i > targetNumber; i--){
        newArray.pop();
    }
    return newArray;
}

export const sortOrderedArray = (orderedArray = []) =>
{
    orderedArray.sort(function(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
    });
}

export const sortOrderedArrayFullName = (orderedArray = []) =>
{
    orderedArray.sort(function(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
        return 0;
    });
}