class Rows
{
    constructor({leagueData = []}) {
        this.rows = this._Rows(leagueData);
    }

    GetRows = () =>
    {
        return this.rows;
    }

    _Rows = (leagueData = []) =>
    {
        const idLookup = this._CreateIdLookup(leagueData);
        let rows = this._CreateRows(leagueData, idLookup);
        return rows;
    }

    _CreateRows = (leagueData = [], idLookup = {}) =>
    {
        let rows = [];
        
        for(const row of leagueData)
        {
            rows.push(this._CreateRow(row.name, row.total, row.scores, idLookup))
        }
        return rows 
    }

    _CreateRow = (name = '', total = '', scores = [], idLookup = {}) => 
    {
        let row = {
            name,
            total
        };

        for(let score of scores)
        {
            const opponentPosition = idLookup[score.opponent];
            row[opponentPosition] = score.score;
        }
        //{ name: blah, total: 0, i1: 0, i2: 0, i3: 0, i4: 0, i5: 0}

        return row;
    }

    _CreateIdLookup = (leagueData = []) =>
    {
        let idLookup = {}
        for(const person in leagueData)
        {
            let personId = leagueData[person].id;
            idLookup[personId] = `i${person.toString()}`;
        }
        return idLookup;
    }
}

export default Rows