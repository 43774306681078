import React, {useEffect} from 'react'
import useEndpoint from "../../../../global-hooks/usePostEndpoint";
import MockData from './MockData.json'

import {useLeaguesLoading} from './useLeaguesLoading'
import {ProvideLeagues} from './useLeagues';
import LoadingComponent from '../../generic/loading/LoadingComponent'
import LeagueSelection from './LeagueSelection'
import LeagueTables from './LeagueTables'
import styled from 'styled-components'

import './Leagues.scss'
import SubTitle from '../../generic/SubTitle';
import {get, put, update, scan} from '../../../../libs/dynamodb-data/adaptors/component-api-adaptor'

const LeaguesStyles = styled.div`
    background-color: ${props => props.backColor? props.backColor : "#fff"};
    color: ${props => props.textColor ? props.textColor : "#000"};
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`;

export default function Leagues(props) {

    const GetMonths = () =>
    {
        const componentApi = `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/892843b7-3741-405f-a43b-db02f88b9373`;
        const component = useEndpoint({
            method: "GET",
            url: componentApi
        });
        return component;
    }

    const component = GetMonths();

    const loading = useLeaguesLoading();

    const updateComponent= async (id, item, type) => {
        loading.setIsSpinning(true);
        item.type = type;
        let updateReturn = await update(id, item);
        console.log(updateReturn);
        loading.setIsSpinning(false);
        return updateReturn;
    }

    const getCSV= async (id, item) => {
        loading.setIsSpinning(true);
        item.type = "CSV Leagues"
        let updateReturn = await get(id, item);
        console.log(updateReturn);
        exportToCsv('League', CreateCSVData(updateReturn))
        loading.setIsSpinning(false);
        return updateReturn;
    }

    const CreateCSVData = (LeaguesReturn) =>
    {
        const headers = ['League', 'Name', 'CNumber', 'Position'];
        const csvRows = [];
        csvRows.push(headers);

        let keys = Object.keys(LeaguesReturn['League1']);
        for(const cnumber of keys)
        {
            const player = LeaguesReturn['League1'][cnumber];
            csvRows.push(['League1', player.name, cnumber, player.position]);
        }
        keys = Object.keys(LeaguesReturn['League2']);
        for(const cnumber of keys)
        {
            const player = LeaguesReturn['League2'][cnumber];
            csvRows.push(['League2', player.name, cnumber, player.position]);
        }
        keys = Object.keys(LeaguesReturn['League3']);
        for(const cnumber of keys)
        {
            const player = LeaguesReturn['League3'][cnumber];
            csvRows.push(['League3', player.name, cnumber, player.position]);
        }
        return csvRows;
    }

    useEffect(()=>{
        if(component.pending) {
            loading.setIsSpinning(true);
        } else if(component.complete && component.data && loading.isSpinning){
            loading.setIsSpinning(false);
            //scrollTop(0);
        }
    },[component])

    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
    
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
    
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    return (<>
        <LoadingComponent isContextControlled={true} context={loading}/>
        
            <ProvideLeagues>
                {
                    component.complete && 
                    component.data && 
                    component.data && 
                    <>
                    <LeaguesStyles {...props}>
                        <LeagueSelection
                            leaguesIndex={component}
                            updateComponent={updateComponent}
                            getCSV={getCSV}
                            admin={props.admin}
                        />
                    </LeaguesStyles>
                    <LeaguesStyles {...props}>
                        <SubTitle text="Squash"/>
                        <LeagueTables
                            leaguesIndex={component}
                            defaultId={component.data.months[0].squashId}
                        />
                        <SubTitle text="Racketball"/>
                        <LeagueTables
                            leaguesIndex={component}
                            defaultId={component.data.months[0].racketId}
                        />
                    </LeaguesStyles>
                    </>
                }
            </ProvideLeagues>
    </>)
}






