import React from 'react'
import Layout from '../layout/Layout';

function AppWithAuth({data}) {
    return (
        <div>
            <Layout data={data}/>
        </div>
    )
}

export default AppWithAuth;
