import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import history from '../../../libs/history';
import './Hyperlink.scss'

export default function Hyperlink(props) {

    const [responsiveObj] = useContext(ResponsiveContext);

    const isInternal = !props.linkInfo.address.includes('.');

    return (
        <div className={`hyperlink-item ${responsiveObj.mobileMd ? "hyperlink-item-mobile" : "" }`}>
            {!isInternal && <a href={props.linkInfo.address}> {props.linkInfo.text}</a>}
            {isInternal && <div onClick={()=>{history.push(props.linkInfo.address);}}> <u>{props.linkInfo.text}</u> </div>}
        </div>
    )
}
