import React, {useContext, createContext, useState} from 'react'

const genericLoadingContext = createContext();

export function ProvideGenericLoading({children}){
    const genericLoading = UseGenericLoading();
    return <genericLoadingContext.Provider value={genericLoading}>{children}</genericLoadingContext.Provider>
}

export const useGenericLoading = () => {
    return useContext(genericLoadingContext);
}

export default function UseGenericLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
