import React, {useContext, createContext, useState} from 'react'

const newsContext = createContext();

export function ProvideNews({children}){
    const news = UseNews();
    return <newsContext.Provider value={news}>{children}</newsContext.Provider>
}

export const useNews = () => {
    return useContext(newsContext);
}

export default function UseNews() {

    const [offset, setOffset] = useState(0);

    return {
        offset, setOffset
    };
}
