import ObjectTools from '../../../../../../libs/object-tools/ObjectTools.js'
import {sortOrderedArray} from '../../../../../../libs/array-tools/arrary-tools.js'

class Positions
{
    constructor({leagueData = []}) {
        this._positions = this._CalculatePositions(leagueData);
    }

    _CalculatePositions = (leagueData = {}) =>
    {
        let data = ObjectTools.DeepCopyObj(leagueData);
        this._CalculateScores(data);
        let orderedArray = this._CreateOrderedArray(data);
        sortOrderedArray(orderedArray);
        return orderedArray;
    }

    _CalculateScores = (leagueData = {}) =>
    {
        const positionIds = Object.keys(leagueData);
        for(const key of positionIds)
        {
            let total = this._CalculateTotal(leagueData[key].scores)
            if(leagueData[key].name === '-') total = -1;
            leagueData[key].total = total;
        }
    }

    _CalculateTotal = (scores = []) =>
    {
        let total = 0;
        for(const score of scores)
        {
            if(/^-?[\d.]+(?:e-?\d+)?$/.test(score.score))
            {
                total += Number(score.score);
            }
        }
        return total;
    }

    _CreateOrderedArray = (leagueData = {}) =>
    {
        let orderedArray = [];
        const positionIds = Object.keys(leagueData);
        for(const key of positionIds)
        {
            orderedArray.push(leagueData[key]);
        }
        return orderedArray;
    }

    GetPositions = () =>
    {
        return this._positions;
    }
}

export default Positions