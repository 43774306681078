import React, {useState, createContext, useEffect} from 'react';
import useEndpoint from "../../global-hooks/usePostEndpoint";
import {ProvideHeader} from '../../components/internal/containers/headers/useHeader';
import {ProvideScroll} from '../../global-hooks/useScroll';
import AppWithAuth from './AppWithAuth';
import {Helmet} from "react-helmet";
import { loadReCaptcha } from 'react-recaptcha-google'
import Amplify from 'aws-amplify';

import './App.scss';

export const ManifestContext = createContext();

export default function App() {
  const manifestApi = "https://2nkbydvc0k.execute-api.eu-west-2.amazonaws.com/dev/site/221a36cd-4dd3-430f-9013-dbab380c61e9";
  const manifest = useEndpoint({
      method: "GET",
      url: manifestApi
  });

  useEffect(()=>{
    loadReCaptcha();
  }, [])

  const [data, setData] = useState(false);

  let app;

  if(manifest.pending) {
    app = 'Loading...'
  } else if(manifest.complete && manifest.data && !data){
    setData(manifest.data);
    //console.log(manifest.data);
    let awsmobile = manifest.data.aws;
    Amplify.configure(awsmobile);
  }

  if(manifest.complete && manifest.data && data){
    app = <>
        <div><h3>Following the feedback of our members we are happy to announce we are moving to a new and improved booking system.</h3></div>
        <a href='https://tamworthsquash.mycourts.co.uk/'><h1>---Click For New Site---</h1></a>
      </>
  }

  return <ManifestContext.Provider value={[data, setData]}>{app}</ManifestContext.Provider>
}