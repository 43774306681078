import React, {useState} from 'react'

export default function useContactForm() {

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [comment, setComment] = useState(null);
    const [error, setError] = useState({
        nameError: false,
        emailError: false,
        commentError: false
    });
    const [success, setSuccess] = useState(null);
    const [fail, setFail] = useState(null);

    const nameSet = (e) => {
        setName(e.target.value);
        setSuccess(false);
        setFail(false);
    }

    const emailSet = (e) => {
        setEmail(e.target.value);
        setSuccess(false)
        setFail(false);
    }

    const commentSet = (e) => {
        setComment(e.target.value);
        setSuccess(false)
        setFail(false);
    }

    const errorSet = (nameError, emailError, commentError) => {
        const newError = {...error};
        newError.nameError = nameError;
        newError.emailError = emailError;
        newError.commentError = commentError;
        setError(newError);
    }

    const verifyCallback = (recaptchaToken) => {
        setToken(recaptchaToken);
    }

    const [token, setToken] = useState(null);

    return {
        name,
        email,
        comment,
        error,
        success,
        fail,
        nameSet,
        emailSet,
        commentSet,
        errorSet,
        verifyCallback,
        token,
        setSuccess,
        setFail,
        errorSet,
        setToken


    }
}
