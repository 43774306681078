
import CheckLogin from '../../checkLogin';
export const apiCall = async (req, isSecure) => {

    let user;
    let token;

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if(isSecure){
        user =  await CheckLogin();
        token = user.signInUserSession.idToken.jwtToken;
        headers.append('Authorization', token);
    }

    return fetch(req.url, {
        method: req.method,
        headers,
        body: JSON.stringify(req.body)
    })
    .then((res)=>{
        return res.json();
    })
    .then(function (data) {
        //console.log('success', data);
        return (data);
    })
    .catch(function (error) {
        console.log('error', error);
        return (error);
    });
}