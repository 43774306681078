import React, {useEffect, useState} from 'react'
import useEndpoint from "../../../../global-hooks/usePostEndpoint";
import {useNewsLoading} from './useNewsLoading'
import MainTitle from '../../generic/MainTitle'
import Text from '../../generic/Text'
import NewsRow from './NewsRow'
import './News.scss'

export default function NewsContainer(props) {
    const newsIndex = props.newsIndex.data;
    const newsId = props.id ? props.id : newsIndex.news[0].id;
    
    const componentApi = `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${newsId}`;
    const component = useEndpoint({
        method: "GET",
        url: componentApi
    });
    const loading = useNewsLoading();

    const [rows, setRows] = useState([]);

    const fillRows = () => {
        let tempRows = [];
        for(let i = 0; i < component.data.rowInfo.length; i++){
            tempRows.push(<NewsRow key={i} rowInfo={component.data.rowInfo[i]}/>);
        }
        setRows(tempRows);
    }

    useEffect(()=>{
        if(component.pending) {
            loading.setIsSpinning(true);
        }else if(component.complete && component.data){
            loading.setIsSpinning(false)
            fillRows();
        }
    },[component,props.id])


    return (<>
        <div className="news-container-card">{component.complete && component.data && component.data && 
            <>
                {component.data.title && <><MainTitle text={component.data.title}/></>}
                {rows.map((row) =>(
                    row
                ))}
                <i><Text text={`${component.data.author} - ${component.data.created}`}/></i>
            </>
        }</div>
        </>
    )
}
