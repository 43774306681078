import React, {useContext, createContext, useState} from 'react'

const loginLoadingContext = createContext();

export function ProvideLoginLoading({children}){
    const loginLoading = UseLoginLoading();
    return <loginLoadingContext.Provider value={loginLoading}>{children}</loginLoadingContext.Provider>
}

export const useLoginLoading = () => {
    return useContext(loginLoadingContext);
}

export default function UseLoginLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
