import React, {useContext, createContext, useState} from 'react'

const contactLoadingContext = createContext();

export function ProvideContactLoading({children}){
    const contactLoading = UseContactLoading();
    return <contactLoadingContext.Provider value={contactLoading}>{children}</contactLoadingContext.Provider>
}

export const useContactLoading = () => {
    return useContext(contactLoadingContext);
}

export default function UseContactLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
