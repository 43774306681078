import React, {useContext, createContext, useState} from 'react'

const scrollContext = createContext();

export function ProvideScroll({children}){
    const scroll = UseScroll();
    return <scrollContext.Provider value={scroll}>{children}</scrollContext.Provider>
}

export const useScroll = () => {
    return useContext(scrollContext);
}
export default function UseScroll() {

    const [isScroll, setIsScroll] = useState(true);

    const setScroll = (x, y) => {
        const container = document.querySelector('.layout-main-container');
        container.scrollTop = y;
        container.scrollLeft = x;
    }

    const setScrollY = (y) => {
        const container = document.querySelector('.layout-main-container');
        container.scrollTop = y;
        setIsScroll(false);
    }

    return {
        setScroll,
        setScrollY,
        isScroll, setIsScroll
    }
}
