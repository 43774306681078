import React, {useContext} from 'react'
import styled from 'styled-components';
import {ResponsiveContext} from 'react-responsive-window'
import './Button.scss'

const Btn = styled.button`
    border: ${props => props.borderWidth ? props.borderWidth : "2px"} solid ${props => props.color ? props.color : "#000"};
    background-color: ${props => props.firstColor ? props.firstColor : "#fff"};
    color: ${props => props.color ? props.color : "#000"};
    border-radius: ${props => props.borderRadius ? props.borderRadius : "0px"};
    width: ${props => props.width ? props.width : ""};
    :hover {
        border-color: ${props => props.firstColor ? props.firstColor : "#fff"};
        background-color: ${props => props.color ? props.color : "#000"};
        color: ${props => props.firstColor ? props.firstColor : "#fff"};
    }
`;


export default function Button(props) {

    const [responsiveObj] = useContext(ResponsiveContext);

    return (
        <Btn {...props} className={(responsiveObj.mobileMd ? "btn-button-mobile" : "btn-button")}>
            
        </Btn>
    )
}
