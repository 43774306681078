import React, {useState} from 'react'
import GoogleMapReact from 'google-map-react'
import './Maps.scss'

export default function Maps(props) {

    return (
        <div className="maps-container">
            <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBtPnOHpavXAWiVUWei1ST3K_Wxonb1Cs4" }}
                    defaultCenter={{lat: parseFloat(props.lat), lng: parseFloat(props.long)}}
                    defaultZoom={14}
                    yesIWantToUseGoogleMapApiInternals = {true}
                >
                
            </GoogleMapReact>
        </div>
    )
}
//<div style={{borderRadius: "50%", width: "20px", background: "#fff", border: "3px solid #ff0000", color: "#fff"}} lat={props.lat} lng={props.long}>.</div>