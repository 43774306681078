import React, {useContext, createContext, useState} from 'react'

const leaguesContext = createContext();

export function ProvideLeagues({children}){
    const leagues = UseLeagues();
    return <leaguesContext.Provider value={leagues}>{children}</leaguesContext.Provider>
}

export const useLeagues = () => {
    return useContext(leaguesContext);
}

export default function UseLeagues() {

    const [id, setId] = useState(null);
    const [selectionIndex, setSelectionIndex] = useState(0);

    return {
        id, setId,
        selectionIndex, setSelectionIndex
    };
}
