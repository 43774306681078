import React from 'react'
import styled from 'styled-components';
import './Loading.scss'


const Spinner = styled.div`
    border: 16px solid #f3f3f3;
    border-top: 16px solid ${props => props.borderTop ? props.borderTop : "#4b4b4b"};
`;

export default function Loading({loading}) {

    return (
        <div className="loading-container">
            <div style={{marginBottom:'20px'}}> 
                <img className="loading-img" src={loading.logo} alt={loading.logoAlt}/>
            </div>
            <Spinner className="loader" {...loading}></Spinner>
        </div>
    )
}
