import React, {useEffect} from 'react'
import useEndpoint from "../../../../global-hooks/usePostEndpoint";
import NewsContainer from './NewsContainer'
import NewsBanner from './NewsBanner'
import {ProvideNews} from './useNews';
import styled from 'styled-components'
import {useNewsLoading} from './useNewsLoading'
import LoadingComponent from '../../generic/loading/LoadingComponent'
import './News.scss'

const NewsStyles = styled.div`
    background-color: ${props => props.backColor? props.backColor : "#fff"};
    color: ${props => props.textColor ? props.textColor : "#000"};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export default function News(props) {
    
    let newsId = props.urlParams ? props.urlParams.substr(1) : "";

    //Get news index and latest news artical
    const componentApi = `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${props.newsIndex}`;
    const component = useEndpoint({
        method: "GET",
        url: componentApi
    });

    const loading = useNewsLoading();

    useEffect(()=>{
        if(component.pending) {
            loading.setIsSpinning(true);
        } else if(component.complete && component.data && loading.isSpinning){
            loading.setIsSpinning(false);
            //scrollTop(0);
        }
    },[component])

    return (<>
        <LoadingComponent isContextControlled={true} context={loading}/>
        <NewsStyles {...props}>
            {!props.isBanner && component.complete && component.data && component.data && <NewsContainer newsIndex={component} id={newsId}/>}
            <ProvideNews>{props.isBanner && component.complete && component.data && component.data && <NewsBanner newsIndex={component}/>}</ProvideNews>
        </NewsStyles>
    </>)
}
