import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import './SubTitle.scss'

export default function SubTitle(props) {
    const [responsiveObj] = useContext(ResponsiveContext);
    return (
        <div className={`sub-title-container ${responsiveObj.mobileMd ? "sub-title-container-mobile" : "" }`}>
            {props.text}
        </div>
    )
}

