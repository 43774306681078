import React, {useContext, createContext, useState} from 'react'

const adminPageLoadingContext = createContext();

export function ProvideAdminPageLoading({children}){
    const adminPageLoading = UseAdminPageLoading();
    return <adminPageLoadingContext.Provider value={adminPageLoading}>{children}</adminPageLoadingContext.Provider>
}

export const useAdminPageLoading = () => {
    return useContext(adminPageLoadingContext);
}

export default function UseAdminPageLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
