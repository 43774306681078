import React, {useContext, createContext, useState} from 'react'

const newsLoadingContext = createContext();

export function ProvideNewsLoading({children}){
    const newsLoading = UseNewsLoading();
    return <newsLoadingContext.Provider value={newsLoading}>{children}</newsLoadingContext.Provider>
}

export const useNewsLoading = () => {
    return useContext(newsLoadingContext);
}

export default function UseNewsLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
