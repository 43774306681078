import {
    getComponent, 
    putComponent, 
    updateComponent, 
    scanComponent,
    deleteComponent
} from '../api-calls/component-api';

export const get = (id) => {
    return new Promise((resolve, reject) => {
      getComponent(id).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const Delete = (id) => {
  return new Promise((resolve, reject) => {
    deleteComponent(id).then((data) => resolve(data)).catch((err) => reject(err));
  });
}


export const put = (id, params) => {
    return new Promise((resolve, reject) => {
      putComponent(id, params).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const update = (id, item) => {
    return new Promise((resolve, reject) => {
      updateComponent(id, item).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const scan = () => {
    return new Promise((resolve, reject) => {
      scanComponent().then((data) => resolve(data)).catch((err) => reject(err));
    });
}
