const screenClassAll = (options, strings) => {
    let returnString;

    if (!options.mobileMd){
        if(!options.mobileXl){
            if(!options.tabletLg){
                if(options.desktopLg){
                    returnString = strings.lg
                } else {
                    returnString = strings.xl
                }
            } else {
                returnString = strings.md
            }
        } else {
            returnString = strings.sm
        }
    } else {
        returnString = strings.xs
    }

    return returnString;
}

const screenClassAdvanced = (options, strings) => {
    let returnString;
    if(!options.mobileSm){
        if (!options.mobileMd){
            if(!options.mobileLg){
                if(!options.mobileXl){
                    if(!options.tabletSm){
                        if(!options.tabletMd){
                            if(!options.tabletLg){
                                if(!options.desktopSm){
                                    if(!options.desktopMd){
                                        returnString = strings.desktopLg
                                    } else {
                                        returnString = strings.desktopMd
                                    }
                                } else {
                                    returnString = strings.desktopSm
                                }
                            } else {
                                returnString = strings.tabletLg
                            }
                        } else {
                            returnString = strings.tabletMd
                        }
                    } else {
                        returnString = strings.tabletSm
                    }
        
                } else {
                    returnString = strings.mobileXl
                }
            } else {
                returnString = strings.mobileLg
            }
    
        } else {
            returnString = strings.mobileMd
        }
    } else {
        returnString = strings.mobileSm
    }


    return returnString;
}
const screenClassAdvancedPre = (pre, options, strings) => {
    return pre + " " + screenClassAdvanced(options, strings);
}

const screenClassAllPre = (pre, options, strings) => {
    return pre + " " + screenClassAll(options, strings);
}

const screenClassAllPost = (options, strings, post) => {
    return screenClassAll(options, strings) + " " + post;
}

const screenClassAllPrePost = (pre, options, strings, post) => {
    return pre + " " + screenClassAll(options, strings) + " " + post;
}

const conditioned = (condition, yes, no) => {
    return (condition?yes:no);
}

const preCondition = (pre, condition, yes, no) => {
    return pre + " " + conditioned(condition, yes, no);
}

const stringSelect = (options, selection) => {
    if (!options || !selection) {
        return '';
    }
    if (typeof selection !== 'string') {
        return '';
    }
    if (selection === ''){
        return '';
    }
    if (options[selection]){
        return options[selection];
    } else {
        return '';
    }
}


const ClassGen = {
    screenClassAdvanced,
    screenClassAdvancedPre,
    screenClassAll,
    screenClassAllPre,
    screenClassAllPost,
    screenClassAllPrePost,
    conditioned,
    preCondition,
    stringSelect
}

export default ClassGen;