import React, { useState, useEffect, useContext, createContext } from "react";
import { Auth } from '@aws-amplify/auth';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null);
    const [sub, setSub] = useState(null);
    const [isHuman, setIsHuman] = useState(null);
    
    const signin = async (username, password) => {

        try {

            const user = await Auth.signIn(username, password);   
            
            if (user.challengeName === 'SMS_MFA' || 
                user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                // You need to get the code from the UI inputs
                // and then trigger the following function with a button click
                //const code = getCodeFromUserInput();
                // If MFA is enabled, sign-in should be confirmed with the confirmation code
                const loggedUser = await Auth.confirmSignIn(
                    user,   // Return object from Auth.signIn()
                    //code,   // Confirmation code  
                    //mfaType // MFA Type e.g. SMS, TOTP.
                );
                setUserInfo(null);
                setError('MFA');
                return({user:null, error: 'MFA'});
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                // You need to get the new password and required attributes from the UI inputs
                // and then trigger the following function with a button click
                // For example, the email and phone_number are required attributes
                //const { username, email, phone_number } = getInfoFromUserInput();
                //const loggedUser = await Auth.completeNewPassword(
                  //  user,               // the Cognito User Object
                    //newPassword,       // the new password
                    // OPTIONAL, the required attributes
                    //{
                      //  email,
                        //phone_number,
                    //}
                //);
                setUserInfo(null);
                setError('NEW_PASSWORD_REQUIRED');
                return({user:null, error: 'NEW_PASSWORD_REQUIRED'});
            } else if (user.challengeName === 'MFA_SETUP') {
                // This happens when the MFA method is TOTP
                // The user needs to setup the TOTP before using it
                // More info please check the Enabling MFA part
                //Auth.setupTOTP(user);
                setUserInfo(null);
                setError('MFA_SETUP');
                return({user:null, error: 'MFA_SETUP'});
            } else {
                // The user directly signs in
                let data = await Auth.currentUserInfo().catch(err => {
                    setUserInfo(null);
                    setError(err);
                    return({user:user, error: err});
                });

                if(data) {
                    setUserInfo(data);
                    setError(null);                 
                    return({user:data, error: null});
                }

            } 
        } catch (err) {
              if (err.code === 'UserNotConfirmedException') {
                  // The error happens if the user didn't finish the confirmation step when signing up
                  // In this case you need to resend the code and confirm the user
                  // About how to resend the code and confirm the user, please check the signUp part
                  console.log(err);
              } else if (err.code === 'PasswordResetRequiredException') {
                  // The error happens when the password is reset in the Cognito console
                  // In this case you need to call forgotPassword to reset the password
                  // Please check the Forgot Password part.
                  console.log(err);
              } else if (err.code === 'NotAuthorizedException') {
                  // The error happens when the incorrect password is provided
                  console.log(err);
              } else if (err.code === 'UserNotFoundException') {
                  // The error happens when the supplied username/email does not exist in the Cognito user pool
                  console.log(err);
              } else {
                  console.log(err);
              }
      
              if(err.message) {
                setUserInfo(null);
                setError(err.message);
                return({user:null, error: err.message});
              } else {
                setUserInfo(null);
                setError(err);
                return({user:null, error: err});
              }
        }

        return "sdifghsidfg"
    };
  
    const signout = () => {
        Auth.signOut({ global: true })
        .then(data => {
            setUserInfo(false);
            setError(true);
            return({user:false, error: null});
        })
        .catch(err => {
            setUserInfo(false);
            setError(err);
            return({user:false, error: err});
        });
    };

    const checkLogin = () => {
        Auth.currentAuthenticatedUser({bypassCache: true})
        .then(data => {
            if(data){
                setUserInfo(data);
            } else {
                setError(true);
            }
          })
          .catch(err => {
              setUserInfo(null);
              setError(err);
          });
    }
  
    useEffect(()=>{
        if(userInfo && userInfo && userInfo.attributes && userInfo.username){
            setAccessLevel(parseFloat(userInfo.attributes['custom:accessLevel']));
            setUserName(userInfo.username);
            setSub(userInfo.attributes.sub);
            setIsHuman((userInfo.username !== 'signin'));
            
        } else {
            setAccessLevel(null);
            setUserName(null);
            setSub(null);
            setIsHuman(null);
        }
    }, [userInfo])

    
    
    // Return the user object and auth methods
    return {
        userName,
        accessLevel,
        sub,
        error,
        signin,
        signout,
        isHuman,
        checkLogin
    };
}