import React from 'react'
import {Helmet} from "react-helmet";
import Hero from '../../internal/containers/hero/Hero'
import Maps from '../../internal/containers/maps/Maps'
import News from '../../internal/containers/news/News'
import Leagues from '../../internal/containers/leagues/Leagues'
import ContactForm from '../../internal/containers/contact/ContactForm'
import TopScroll from '../../internal/generic/TopScroll'
import {ProvideNewsLoading} from '../../internal/containers/news/useNewsLoading'
import {ProvideContactLoading} from '../../internal/containers/contact/useContactLoading'
import {ProvideLeaguesLoading} from '../../internal/containers/leagues/useLeaguesLoading'
import './HomePage.scss';

export default function HomePage(props) {

    let components = [];
    let data = props.data;
    let manifest = props.manifest;

    if(data.components){
        for(let i = 0; i < data.components.length; i++){
            if(data.components[i].component === 'Hero') {
                components.push(<Hero key={i} {...data.components[i]} {...data.buttons}/>);
            }
            if(data.components[i].component === 'Maps') {
                components.push(<Maps key={i} {...data.components[i]}/>);
            }
            if(data.components[i].component === 'Contact') {
                components.push(
                    <ProvideContactLoading key={i}>
                        <ContactForm  {...data.components[i]} {...data.buttons}/>    
                    </ProvideContactLoading>
                );
            }
            if(data.components[i].component === 'NewsBanner') {
                components.push(
                    <ProvideNewsLoading key={i}>
                        <News newsIndex={manifest.news} {...data.components[i]} {...data.buttons} isBanner={true}/>
                    </ProvideNewsLoading>
                );
            }

            if(data.components[i].component === 'Leagues') {
                components.push(
                    <ProvideLeaguesLoading key={i}>
                        <Leagues {...data.components[i]} {...data.buttons} {...data.buttons} />
                    </ProvideLeaguesLoading>
                );
            }
        }
    }

    return (
        <div>
            <Helmet>
                <title>{data.title}</title>
            </Helmet>

            <TopScroll content={components.map((component) =>(
                    component
            ))}/>

        </div>
    )
}
