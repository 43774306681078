class ObjectTools
{
    /**
     * @brief The will shallow copy an object.
     * @param {object}  obj Original Object/Array
     * @returns {object} Copied Object
     */
    static ShallowCopyObj(obj)
    {
        return {...obj}
    }

    /**
     * @brief The will shallow copy an array.
     * @param {array}  arr Original Array
     * @returns {array} Copied Array
     */
    static ShallowCopyArray(arr)
    {
        return [...arr]
    }

    /**
     * @brief The will deep copy an object. DO NOT USE ON OBJECTS THAT CONTAIN FUNCTIONS!!
     * @param {object}  obj Original Object/Array
     * @returns {object} Copied Object
     */
    static DeepCopyObj(obj)
    {
        return JSON.parse(JSON.stringify(obj));
    }

}

export default ObjectTools