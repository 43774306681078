import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import history from '../../../../libs/history';
import {ResponsiveContext} from 'react-responsive-window'
import {FaBars, FaUserAlt, FaWindowClose} from 'react-icons/fa';
import {useHeader} from './useHeader';
import CheckLogin from '../../../../libs/checkLogin'
import {ManifestContext} from '../../../../root/entry/App';
import {useAuth} from "../../../../global-hooks/useAuth";
import {useScroll} from '../../../../global-hooks/useScroll';
import {useHeaderLoading} from './useHeaderLoading';
import LoadingComponent from '../../generic/loading/LoadingComponent'


import styled from 'styled-components';
import './Header.scss';


const HeaderContainer = styled.div`
  background: ${props => props.backColor ? props.backColor : "#ffffff"};
  color: ${props => props.textColor ? props.textColor : "#4b4b4b"};
`;

export const LinkButton = styled.button`
    font-size: ${props => props.fontSize ? props.fontSize : "14px"};
    border: 0px;
    padding: 0px;
    background: ${props => props.backColor ? props.backColor : "rgba(255,255,255,0)"};
    color: ${props => props.textColor ? props.textColor : "#4b4b4b"};
    :hover {
        color: ${props => props.textColor ? props.textColor : "#4b4b4b"};
        font-weight:bold;
    }
    :active { 
        outline: none;
        border: none;
    }
    :focus {
        outline:0;
    }
`
export default function Header() {
    
    const headerContext = useHeader();
    const loading = useHeaderLoading();
    const [responsiveObj] = useContext(ResponsiveContext);
    const [data, setData] = useContext(ManifestContext);
    const auth = useAuth();
    const scroll = useScroll();
    const headerData = data.header;
    const routesData = data.routes;

    const loginClick = async () => {
        if(!loading.isSpinning){
            loading.setIsSpinning(true)
        };

        let user = await CheckLogin();

        if(user){
            if(user.attributes && user.attributes['custom:accessLevel'] > 4){
                scroll.setIsScroll(true);
                loading.setIsSpinning(false);
                history.push('admin');
            } else {
                scroll.setIsScroll(true);
                loading.setIsSpinning(false);
                history.push('home')
            }
        } else {
            scroll.setIsScroll(true);
            loading.setIsSpinning(false);
            history.push('bookings')
        }
        
    };
    const menuClick = () => {headerContext.setDrop(!headerContext.drop)};

    let logo = <button className="header-button-stealth" onClick={()=>{scroll.setIsScroll(true); headerContext.setDrop(false); history.push('home'); }}>
            <img className="header-logo" src={headerData.logo} alt={(headerData.logoAlt ? headerData.logoAlt : "logo") }/>
        </button>
    let login = <button className="header-button-stealth header-login-icon" onClick={()=>{loginClick(); headerContext.setDrop(false);}}>
                    <FaUserAlt className="header-login-icon" fontSize={'inherit'}/>
                </button>

    let menuIcon;

    if (headerContext.drop) {
        menuIcon = <FaWindowClose className="header-login-icon" fontSize={'inherit'}/>
    } else {
        menuIcon = <FaBars className="header-login-icon" fontSize={'inherit'}/>
    }

    let menu = <button className="header-button-stealth header-login-icon" onClick={()=>{menuClick()}}>
                    {menuIcon}
                </button>

    
    let links = [];

    for(let i = 0; i < routesData.length; i++){
        if(routesData[i].topLink){
            links.push(<div className="header-col" key={i}>
                <LinkButton onClick={()=>{
                    scroll.setIsScroll(true); 
                    history.push(routesData[i].urlString)
                }}>
                    {routesData[i].title}
                </LinkButton>
            </div>)
        }
    }

    let header;

    if (responsiveObj.mobileXl) {
        header = <div className="header-row-mobile">
                    <div className="header-col-left">{menu}</div>
                    <div className="header-col-center">{logo}</div>
                    <div className="header-col-right">{login}</div>
                </div>
    } else {
        header = <div className="header-row">
                <div className="header-col">{logo}</div>
                {links.map((link) =>(
                    link
                ))}
                <div className="header-col" xs={2} sm={2} md={2} lg={2} xl={2}>{login}</div>
            </div>
    }
    
    return (<>
                <LoadingComponent isContextControlled={true} context={loading}/>
                <HeaderContainer className="header-container" 
                    textColor={headerData.textColor} 
                    backColor={headerData.backColor} 
                    fontSize={headerData.fontSize}
                >
                    <Container className="header-outer" fluid={true}>
                        {header}
                    </Container>
                </HeaderContainer>
            </>
    )
}