import React, {useContext, createContext, useState} from 'react'

const headerContext = createContext();

export function ProvideHeader({children}){
    const header = UseHeader();
    return <headerContext.Provider value={header}>{children}</headerContext.Provider>
}

export const useHeader = () => {
    return useContext(headerContext);
}

export default function UseHeader() {

    const [drop, setDrop] = useState(null);
    const [data, setData] = useState(null);

    return {
        drop,
        setDrop,
        data,
        setData
    };
}
