import React, {useState, useContext} from 'react'
import MainTitle from '../../generic/MainTitle'
import NewsCard from './NewsCard'
import {ResponsiveContext} from 'react-responsive-window'
import {FaArrowAltCircleLeft, FaArrowAltCircleRight, FaArrowAltCircleUp, FaArrowAltCircleDown } from  "react-icons/fa";
import './News.scss'

export default function NewsBanner(props) {

    const [offset,setOffset] = useState(0);
    const [responsiveObj] = useContext(ResponsiveContext);

    const left = () => {
        if(0 + offset > 0){
            setOffset(offset - 1);
        }
    }

    const right = () => {
        if(3 + offset < props.newsIndex.data.news.length){
            setOffset(offset + 1);
        }
    }
    

    return (<div className="news-banner-container">
        
        <MainTitle text={'Latest News'}/>
        {responsiveObj.mobileLg && <div className="news-up-banner-button" onClick={()=>{left()}}><FaArrowAltCircleUp/> Prev</div>}
        <div className="news-banner">
            <NewsCard id={props.newsIndex.data.news[0 + offset].id}/>
            <NewsCard id={props.newsIndex.data.news[1 + offset].id}/>
            <NewsCard id={props.newsIndex.data.news[2 + offset].id}/>
        </div>
        {!responsiveObj.mobileLg && <div className="news-right-banner-button" onClick={()=>{right()}}><FaArrowAltCircleRight/></div>}
        {!responsiveObj.mobileLg && <div className="news-left-banner-button" onClick={()=>{left()}}><FaArrowAltCircleLeft/></div>}
        {responsiveObj.mobileLg && <div className="news-down-banner-button" onClick={()=>{right()}}><FaArrowAltCircleDown/>Next</div>}
    </div>)
}
