import React, {useContext, useEffect} from 'react'
import Image from '../../generic/Image'
import SubTitle from '../../generic/SubTitle'
import Text from '../../generic/Text'
import useEndpoint from "../../../../global-hooks/usePostEndpoint";
import history from '../../../../libs/history';
import {useNewsLoading} from './useNewsLoading'
import {ScrollContext} from '../../../../root/layout/Layout';
import {ResponsiveContext} from 'react-responsive-window'
import './News.scss'

export default function NewsCard(props) {

    const scrollTop = useContext(ScrollContext);
    const [responsiveObj] = useContext(ResponsiveContext);
    const componentApi = `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${props.id}`;
    const component = useEndpoint({
        method: "GET",
        url: componentApi
    });
    const loading = useNewsLoading();

    useEffect(()=>{
        if(component.pending) {
            loading.setIsSpinning(true);
        }else if(component.complete && component.data && component.data){
            loading.setIsSpinning(false)
        }
    },[component])

    return (
        <div className={`news-card`} onClick={()=>{ history.push('news' + "?" + props.id); history.go(); scrollTop(0);}}>
            {component.data && <>
                <Image image={component.data.thumbnail} imageAlt={component.data.thumbnailAlt} imageParam={"width"} imageParamValue={`${responsiveObj.mobileMd ? "250" : "300" }`}/>
                <SubTitle text={component.data.title}/>
                <i><Text text={component.data.created}/></i>
            </>}
        </div>
    )
}
