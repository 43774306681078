import React from 'react'
import SubTitle from '../../generic/SubTitle'
import ProcessLeague from './component-libs/process-league/ProcessLeague.js'

export default function LeagueTable({
    league,
    leagueName
}) {

    const processedLeague = new ProcessLeague({league: league});

    const leagueData = processedLeague.GetLeagueData();
    const columns = leagueData.columns;
    const data = leagueData.rows;

    const createHeaders = () =>
    {
        let headers = [];
        for(const header of columns)
        {
            const key = leagueName.trim() + header.Header.trim() + 'th';
            headers.push(
                <th key={key}>{header.Header}</th>
            )
        }
        return <tr>
                {headers.map((header)=>(
                    header
                ))}
            </tr>
    }

    const creatRow = (row, count) =>
    {
        if(row.total === -1) row.total = '-';
        return <tr>
                <td>{count}</td>
                <td>{row.name}</td>
                {createPlayerCells(row)}
                <td>{row.total}</td>
            </tr>
    }

    const createPlayerCells = (row) => 
    {
        // <td>{row.i0}</td>
        // <td>{row.i1}</td>
        // <td>{row.i2}</td>
        // <td>{row.i3}</td>
        // <td>{row.i4}</td>
        let cells = [];
        for(let header = 0; header < columns.length-3; header++)
        {

            cells.push(
                <td key={header}>{row[`i${header}`]}</td>
            )
        }
        return cells.map((cell)=>(
                cell
            ))
    }

    const createRows = () =>
    {
        let rows = [];

        let count = 1;

        for(const row of data)
        {
            rows.push(
                creatRow(row, count)
            )
            count++;
        }

        return <>
            {rows.map((row)=>(
                row
            ))}
        </>
    }

    return (
        <div className="league-table-container">
            <SubTitle text={leagueName}></SubTitle>
            <table>
                <thead>
                    {createHeaders()}
                </thead>
                <tbody>
                    {createRows()}
                </tbody>

            </table>
            
        </div>
    )
}
