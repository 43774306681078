import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import Image from '../../generic/Image'
import Hyperlink from '../../generic/Hyperlink'
import SubTitle from '../../generic/SubTitle'
import Text from '../../generic/Text'
import TextList from '../../generic/TextList'

export default function NewsRow(props) {

    let coloums = [];
    let texts = [];
    let textKey = 0;
    const [responsiveObj] = useContext(ResponsiveContext);

    if(props.rowInfo.image !== "" && (props.rowInfo.textLeft || responsiveObj.mobileXl)){
        coloums.push(<Image key={0} {...props.rowInfo}/>);
        textKey = 1;
    }

    for(let i = 0; i < props.rowInfo.text.length; i++){
        //subtitle
        //text
        //Ordered List
        //link

        switch (props.rowInfo.text[i].type) {
            case "sub-title":
                texts.push(<SubTitle key={i} text={props.rowInfo.text[i].text}/>);
                break;
            case "text":
                texts.push(<Text key={i} text={props.rowInfo.text[i].text}/>);
                break;
            case "list":
                texts.push(<TextList key={i} listInfo={props.rowInfo.text[i]}/>);
                break;
            case "link":
                texts.push(<Hyperlink key={i} linkInfo={props.rowInfo.text[i]}/>);
                break;
        
            default:
                break;
        }
    }

    if(texts.length > 0){
        coloums.push(
            <div key={textKey} className="news-row-item">
                {texts.map((text) =>(
                    text
                ))}
            </div>

        )
    }

    if(props.rowInfo.image !== "" && !props.rowInfo.textLeft && !responsiveObj.mobileXl){
        coloums.push(<div key={1} className="news-row-item" ><Image {...props.rowInfo}/></div>);
    }
    
    return (
        <div className="news-row-outer">
            <div className={`news-row-container`}>
                {coloums.map((coloum) =>(
                    coloum
                ))}
            </div>
        </div>

    )
}
