import React, {useEffect}  from 'react'
import useEndpoint from "../../../../global-hooks/usePostEndpoint";
import {useLeaguesLoading} from './useLeaguesLoading'
import LoadingComponent from '../../generic/loading/LoadingComponent'
import LeagueTable from './LeagueTable'
import {useLeagues} from './useLeagues';

export default function LeagueTables({
    defaultId
}) {

    const leagueState = useLeagues();
    const loading = useLeaguesLoading();

    const GetMonthLeagues = (id) =>
    {
        const componentApi = `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${id}`;
        const component = useEndpoint({
            method: "GET",
            url: componentApi
        });
        return component;
    }

    const component = GetMonthLeagues(leagueState.id ? leagueState.id : defaultId);

    useEffect(()=>{
        if(component.pending) {
            loading.setIsSpinning(true);
        } else if(component.complete && component.data && loading.isSpinning){
            loading.setIsSpinning(false);
            //scrollTop(0);
        }
    },[component])

    return (<>
        <LoadingComponent isContextControlled={true} context={loading}/>
        {
            component.complete && 
            component.data && 
            component.data && 
            <>
                {Object.keys(component.data['League1'])[0] && <LeagueTable
                    league={component.data['League1']}
                    leagueName={'League 1'}
                />}
                {Object.keys(component.data['League2'])[0] && <LeagueTable
                        league={component.data['League2']}
                        leagueName={'League 2'}
                />}
                {Object.keys(component.data['League3'])[0] && <LeagueTable
                        league={component.data['League3']}
                        leagueName={'League 3'}
                />}
            </>
        }
    </>)
}
