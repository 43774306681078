import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import './Text.scss'

export default function Text(props) {
    const [responsiveObj] = useContext(ResponsiveContext);
    return (
        <div className={`text-item ${responsiveObj.mobileMd ? "text-item-mobile" : "" }`}>
            {props.text}
        </div>
    )
}
