import {apiCall} from './api-call';

let headers = new Headers();
headers.append('Content-Type', 'application/json');

export const getComponent = (id) => {
    return new Promise((resolve, reject) => {
        const apiObject = {
            method: "GET",
            url: `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${id}`
        }
        apiCall(apiObject, true).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const putComponent = (id, params) => {
    return new Promise((resolve, reject) => {
        const apiObject = {
            method: "POST",
            url: `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/`,
            headers,
            body: params
        }
        apiCall(apiObject, true).then((data) => resolve(data)).catch((err) => reject(err));
    });
}


export const deleteComponent = (id) => {
    return new Promise((resolve, reject) => {
        const apiObject = {
            method: "DELETE",
            url: `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${id}`,
            body: {}
        }
        apiCall(apiObject, true).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const updateComponent = (id, item) => {
    return new Promise((resolve, reject) => {

      const apiObject = {
        method: "PUT",
        url: `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/component/${id}`,
        body: item
      }
      apiCall(apiObject, true).then((data) => resolve(data)).catch((err) => reject(err));
    });
}

export const scanComponent = () => {
    return new Promise((resolve, reject) => {
        const apiObject = {
            method: "GET",
            url: `https://6akruox0dd.execute-api.eu-west-2.amazonaws.com/dev/components/`
        }
      apiCall(apiObject, true).then((data) => resolve(data)).catch((err) => reject(err));
    });
}
