import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import './MainTitle.scss'

export default function MainTitle(props) {
    const [responsiveObj] = useContext(ResponsiveContext);
    return (
        <div className={`main-title-container ${responsiveObj.mobileMd ? "main-title-container-mobile" : "" }`}>
            {props.text}
        </div>
    )
}
