class Columns 
{
    constructor({NoOfPlayers = 5}){
        this._columns = this._CreateColumns(NoOfPlayers);
    }

    GetColumns = () => 
    {
        return this._columns;
    }

    _CreateColumns = (NoOfPlayers = 5) =>
    {
        let columns = [];

        columns.push({
            Header: "",
            accessor: ""
        })

        columns.push({
            Header: "Name",
            accessor: "name"
        })

        for(let i = 0; i < NoOfPlayers; i++){
            columns.push({
                Header: (i+1).toString(),
                accessor: `i${i.toString()}`
            })
        }

        columns.push({
            Header: "Total",
            accessor: "total"
        })

        return columns;
    }
}

export default Columns;