
import Columns from './Columns.js';
import Positions from './Positions.js';
import Rows from './Rows.js'

class ProcessLeague
{
    constructor({league})
    {
        this.league = this._ProcessLeague(league);
    }

    GetLeagueData = () =>
    {
        return this.league;
    }

    _ProcessLeague = (league) => 
    {
        return {
            columns: this._CreateColumns(league),
            rows: this._Rows(league)
        }
    }

    _CreateColumns = (league) =>
    {
        const playerIds = Object.keys(league);
        let columns = new Columns(
        {
            NoOfPlayers: playerIds.length
        });
        return columns.GetColumns();
    }

    _Rows = (league) =>
    {
        const leagueData = this._CreatePositions(league);
        return this._CreateRows(leagueData);
    }

    _CreatePositions = (league) => 
    {
        const playerIds = Object.keys(league);
        let positions = new Positions({
            leagueData: league
        });
        return positions.GetPositions();
    }

    _CreateRows = (leagueData) => 
    {
        let rows = new Rows({
            leagueData: leagueData
        })
        return rows.GetRows();
    }
}

export default ProcessLeague;