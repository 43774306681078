import React, { useState } from 'react'
import Select from 'react-select';
import Button from '../../generic/Button';
import { useLeagues } from './useLeagues';
import { v4 as uuidv4 } from 'uuid';
import ObjectTools from '../../../../libs/object-tools/ObjectTools';

export default function LeagueSelection({
    leaguesIndex,
    updateComponent,
    admin,
    getCSV
}) {

    const leagueState = useLeagues();
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [updateMonth, setUpdateMonth] = useState();

    const fileReader = new FileReader();

    const checkDuplicates = (values, prop) =>
    {
        let valueArr = values.map(function(item){ return item[prop] });
        let isDuplicate = valueArr.some(function(item, idx){ 
            return valueArr.indexOf(item) != idx 
        });
        return isDuplicate;
    }


    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).trim().split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).trim().split("\n");

        const array = csvRows.map(i => {
            const iMod = i.replace(/[\r\n]/gm, '');
            const values = iMod.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });

        console.log('ArrayAsInput', array);

        return array;
    };

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const csvProcessing = players => {
        for (const player in players) {
            if (!players[player].League || players[player].League === '' || !players[player].Name) {
                players.splice(player, 1);
                //alert('Empty row cleared!');
                continue;
            }

            if (players[player].Name === '-') {
                players.splice(player, 1);
                //alert('Player Placeholder Removed!');
                continue;
            }
            players[player].Position.replace('\\r', '');

            if(!isNumeric(players[player].Position))
            {
                console.log('Bad Position', players[player] , players[player].Position);
                players[player].Position = players.length;
            }
        }
        if(checkDuplicates(players, 'CNumber')) {
            alert('Duplicate CNumber');
            return false;
        }
        if(checkDuplicates(players, 'Name'))
        {
            alert('Duplicate Name');
            return false;
        }
        return players;
    }

    const sortByPosition = (a, b) => {
        if (Number(a.Position) < Number(b.Position)) {
            return -1;
        } else return 1;
    }

    const sortPlayers = players => {
        let League1 = [];
        let League2 = [];
        let League3 = [];
        let sortedPlayers = [];
        console.log('#### Players', ObjectTools.DeepCopyObj(players));
        for (const player in players) {
            if (players[player].League === 'League1') League1.push(players[player]);
            else if (players[player].League === 'League2') League2.push(players[player]);
            else if (players[player].League === 'League3') League3.push(players[player]);
            else {alert('League does not exist');}
        }

        League1.sort(sortByPosition);
        League2.sort(sortByPosition);
        League3.sort(sortByPosition);

        console.log('#### League1', ObjectTools.DeepCopyObj(League1));

        fillEmptyPositions(League1);
        if(League2[0])fillEmptyPositions(League2);
        if(League3[0])fillEmptyPositions(League3);

        sortedPlayers = [...League1, ...League2, ...League3];
        return sortedPlayers;
    }

    const fillEmptyPositions = league => {
        for (const player in league) {
            league[player].Position = Number(player) + 1
        }
        console.log('#### league', league)
        for (let i = league.length; i < 8; i++) {
            league.push({
                "League": league[0].League,
                "Name": "-",
                "CNumber": `empty-${uuidv4()}`,
                "Position": i + 1
            })
        }
        return league;
    }

    const AddIndex = (leagueIndex={}, leagueDates={}, squashId='', racketBallId='') =>
    {
        const newLeaguesIndex = ObjectTools.DeepCopyObj(leagueIndex);
        if(newLeaguesIndex.months)
        {
            newLeaguesIndex.months.unshift(
                {
                    "name": leagueDates.value.monthName,
                    "squashId": squashId,
                    "racketId": racketBallId,
                    "leagueMonth": leagueDates.value.leagueMonth,
                    "live": "true"
                }
            )
            return newLeaguesIndex;
        }
        return false;
    }


    const AmendIndex = (leagueIndex={}, leagueDates={}, squashId='', racketBallId='') =>
    {
        const newLeaguesIndex = ObjectTools.DeepCopyObj(leagueIndex);
        if(newLeaguesIndex.months)
        {
            newLeaguesIndex.months[0] = {
                "name": leagueDates.value.monthName,
                "squashId": squashId,
                "racketId": racketBallId,
                "leagueMonth": leagueDates.value.leagueMonth,
                "live": "true"
            }
            return newLeaguesIndex;
        }
        return false;
    }

    const CreateEmpty = (id, sport, monthName) => 
    {
        return {
            id,
            'aSport': sport,
            'label': monthName,
            'League1': {},
            'League2': {},
            'League3': {}
        }
    }
    
    const CreateBlankPlayers = () =>
    {
        let blankPlayers = [];

        CreateBlankPlayersPerLeague('League1', blankPlayers);
        CreateBlankPlayersPerLeague('League2', blankPlayers);
        CreateBlankPlayersPerLeague('League3', blankPlayers);
        
        return blankPlayers;
    }

    const CreateBlankPlayersPerLeague = (leagueName='', blankPlayers=[]) =>
    {
        for(let i = 0; i < 8; i++){blankPlayers.push({"League": leagueName,"Name": `-`,"CNumber": `empty-${i}`,"Position": i+1});}
    }

    const CreateBlankScores = (players) =>
    {
        let scores = [];

        for(let i = 0; i < players.length; i++)
        {
            scores.push({
                'opponent': players[i].CNumber,
                'score': '-'
            });
        }

        return scores;
    }

    const CreatePlayer = (leauge, player, players) =>
    {
        leauge[player.CNumber] = {
            'id': player.CNumber,
            'name': player.Name,
            'position': player.Position,
            'scores': CreateBlankScores(players)
        };

        leauge[player.CNumber].scores[player.Position-1] = {
            'opponent': player.CNumber,
            'score': 'x'
        };

    }

    const CreatePlayers = (league, players) =>
    {
        for(const player of players)
        {
            CreatePlayer(league, player, players);
        }
    }

    const PopulateLeagues = (blankSport, players) =>
    {
        let League1 = [];
        let League2 = [];
        let League3 = [];
        for (const player in players) {
            if (players[player].League === 'League1') League1.push(players[player]);
            else if (players[player].League === 'League2') League2.push(players[player]);
            else if (players[player].League === 'League3') League3.push(players[player]);
            else {alert('League does not exist'); return;}
        }
        CreatePlayers(blankSport.League1, League1);
        CreatePlayers(blankSport.League2, League2);
        CreatePlayers(blankSport.League3, League3);
    }

    const upload = () => {
        console.log('LeaguesIndex', leaguesIndex.data);
        const squashId = uuidv4();
        const racketBallId = uuidv4();
        const lastMonthName = leaguesIndex.data.months[leagueState.selectionIndex].name;
        const desiredMonth = updateMonth.value.monthName; 
        let squash = {};
        let racketBall = {};
        console.log('Update Month', updateMonth);
        const newLeaguesIndex = (lastMonthName !== desiredMonth) 
            ? AddIndex(leaguesIndex.data, updateMonth, squashId, racketBallId) : 
            AmendIndex(leaguesIndex.data, updateMonth, squashId, racketBallId);

        if(newLeaguesIndex){
            console.log('NewLeaguesIndex', newLeaguesIndex);
            squash = CreateEmpty(squashId, 'Squash', updateMonth.value.monthName);
            racketBall =  CreateEmpty(racketBallId, 'Racketball', updateMonth.value.monthName);
            PopulateLeagues(squash, array);
            PopulateLeagues(racketBall, CreateBlankPlayers());
            //Push squash, racketBall, newLeaguesIndex;
            console.log('STUFF', squash, racketBall, newLeaguesIndex);
            updateComponent('undefined', {index: newLeaguesIndex, squash, racketBall}, 'NewMonthCSV')
            //alert('Nope! Still nothing useful here!');
            return;
        }

        return alert('Failed');
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                const playerArray = csvFileToArray(text);
                const cleanPlayersArray = csvProcessing(playerArray);
                if(cleanPlayersArray){
                    const sortedFilledPlayerArray = sortPlayers(cleanPlayersArray);
                    console.log(sortedFilledPlayerArray);
                    setArray(sortedFilledPlayerArray);
                }
            };

            fileReader.readAsText(file);
        }
    };

    const headerKeys = Object.keys(Object.assign({}, ...array));

    const defaultValue = {
        month: leaguesIndex.data.months[0],
        index: 0
    };
    const defaultName = leaguesIndex.data.months[0].name;
    const defaultSelection = { value: defaultValue, label: defaultName };

    const selections = []

    const CreateSelections = () => {
        for (const month in leaguesIndex.data.months) {
            if (leaguesIndex.data.months[month].live !== 'false') {
                selections.push(
                    {
                        value: {
                            month: leaguesIndex.data.months[month],
                            index: month
                        },
                        label: leaguesIndex.data.months[month].name
                    }
                )
            }

        }
    }

    const CreateCurrentSelection = () => {
        return {
            value: {
                month: leaguesIndex.data.months[leagueState.selectionIndex],
                index: leagueState.selectionIndex
            },
            label: leaguesIndex.data.months[leagueState.selectionIndex].name
        }
    }

    const selectionsUpdate = [];

    //GET Current month

    const GetLeagueMonth = (date='2022-02-01') =>
    {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        let startDate = new Date(date);
        startDate.setHours(0,0,0,0);
        startDate.setDate(1);
        return {
            leagueMonth: startDate.getTime(),
            monthName: `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`
        }
    }

    const CreateUpdateMonths = () =>
    {
        let today = new Date();
        today.setDate(1);
        //today.setMonth(today.getMonth());
        PushToSelectionUpdate(today.toISOString().substring(0,10));
        today.setMonth(today.getMonth()+1);
        PushToSelectionUpdate(today.toISOString().substring(0,10));
    }

    const PushToSelectionUpdate = (date) =>
    {
        const leagueMonth = GetLeagueMonth(date)
        selectionsUpdate.push(
            {
                value: leagueMonth,
                label: leagueMonth.monthName
            }
        )
    }

    const SetMonthId = (e) => {
        leagueState.setId(e.value.month.squashId);
        leagueState.setSelectionIndex(e.value.index);
    }

    const SetUpdateMonth = e => {
        setUpdateMonth(e);
    }

    CreateSelections();
    CreateUpdateMonths();

    console.log("leagueState", leagueState);
    console.log("leaguesIndex", leaguesIndex);

    return (
        <div>

            {admin &&
                <>
                    <Select
                        className="leagues-select"
                        isSearchable={true}
                        value={updateMonth}
                        options={selectionsUpdate}
                        placeholder={'UpdateMonth'}
                        onChange={(e) => { SetUpdateMonth(e) }}
                    />
                    {/* <Button onClick={() => { updateComponent('undefined', {}, 'NewMonth') }}>Create Month</Button>
                    <Button onClick={() => { updateComponent('undefined', {}, 'NewMonthClear') }}>Create Month and Clear Scores</Button> */}
                    <div style={{ textAlign: "center" }}>
                        <form>
                        {updateMonth &&
                                <>
                            <input
                                type={"file"}
                                id={"csvFileInput"}
                                accept={".csv"}
                                onChange={handleOnChange}
                            />
                            {file && 
                                <>
                                    <button
                                        onClick={(e) => {
                                            handleOnSubmit(e);
                                        }}
                                    >
                                        Preview
                                    </button>

                                    <button
                                        onClick={(e) => {
                                            upload(e);
                                        }}
                                    >
                                        Upload
                                    </button>
                                </>
                            }

                            </>}
                        </form>

                        <br />

                        <table>
                            <thead>
                                <tr key={"header"}>
                                    {headerKeys.map((key) => (
                                        <th>{key}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {array.map((item) => (
                                    <tr key={item.id}>
                                        {Object.values(item).map((val) => (
                                            <td style={{backgroundColor: `${item.Name === '-' ? '#000': '#fff'}`}}>{val}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Button onClick={() => { getCSV(leaguesIndex.data.months[0].squashId, {}) }}>DownloadCSV</Button>
                </>

            }

            <Select
                className="leagues-select"
                isSearchable={true}
                value={leagueState.id ? CreateCurrentSelection() : defaultSelection}
                options={selections}
                placeholder={'Month'}
                onChange={(e) => { SetMonthId(e) }}
            />

        </div>
    )
}
