import { Auth } from '@aws-amplify/auth';

const CheckLogin = () => {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser({bypassCache: true})
        .then(data => {
            if(data){
                //console.log(data);
                resolve(data);
            } else {
                resolve(false);
            }
        })
        .catch(err => {
            console.log(err);
            resolve(false);
        });
    })
}

export default CheckLogin;