import React, {useContext, createContext, useState} from 'react'

const leaguesLoadingContext = createContext();

export function ProvideLeaguesLoading({children}){
    const leaguesLoading = UseLeaguesLoading();
    return <leaguesLoadingContext.Provider value={leaguesLoading}>{children}</leaguesLoadingContext.Provider>
}

export const useLeaguesLoading = () => {
    return useContext(leaguesLoadingContext);
}

export default function UseLeaguesLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
