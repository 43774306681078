import React, { useContext } from 'react'
import { ManifestContext } from '../../../../root/entry/App';
import {useHeader} from './useHeader';
import {ResponsiveContext} from 'react-responsive-window'
import {LinkButton} from './Header'
import history from '../../../../libs/history';
import {useScroll} from '../../../../global-hooks/useScroll';
import styled from 'styled-components';
import './DropMenu.scss'

const DropContainer = styled.div`
    position: fixed;
    background: ${props => props.backColor ? props.backColor : "#ffffff"};
    z-index: 10;
    width: 100%;
    top: 65px;
    left: 0px;
    color: ${props => props.textColor ? props.textColor : "#4b4b4b"};
    text-align: center;
    overflow: hidden;
    line-height: 30px;
    box-shadow: 0px 0px 2px 1px #ddd;
`;


export default function DropMenu() {

    const [data, setData] = useContext(ManifestContext);
    const [responsiveObj] = useContext(ResponsiveContext);
    const scroll = useScroll();
    
    const headerContext = useHeader();
    const headerData = data.header;
    const routesData = data.routes;

    let links = [];

    for(let i = 0; i < routesData.length; i++){
        if(routesData[i].topLink){
            links.push(<div key={i}><div className={(headerContext.drop && responsiveObj.mobileXl ? "drop-bar-row" : "drop-bar-row-closed")}>
                <LinkButton onClick={()=>{ scroll.setIsScroll(true); headerContext.setDrop(false); history.push(routesData[i].urlString);}}>{routesData[i].title}</LinkButton>
            </div><div className="drop-bar-break"></div></div>)
        }
    }

    return (
        <DropContainer className={(headerContext.drop && responsiveObj.mobileXl ? "drop-bar-down" : "drop-bar-closed")} {...headerData}>
                {links.map((link) =>(
                    link
                ))}
        </DropContainer>
    )
}
