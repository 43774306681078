import { Auth } from '@aws-amplify/auth';
export default function SignOut() {
    return new Promise((resolve)=>{
        Auth.signOut({ global: true })
        .then(data => {
            resolve(true);
        })
        .catch(err => {
            resolve('error')
        });
    })
}
