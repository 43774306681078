import React from 'react'
import './Image.scss'

export default function Image(props) {

    let isWidth = props.imageParam === "width"
    let paramValue = props.imageParamValue + "px" 

    return (
        <img className="image" src={props.image} width={(isWidth ? paramValue : "")} height={(!isWidth ? paramValue : "")} alt={props.imageAlt}/>
    )
}
