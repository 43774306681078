import React, { useContext } from 'react'
import {ManifestContext} from '../../../../root/entry/App';
import SignOut from '../../../../libs/signOut'
import styled from 'styled-components';
import './Footer.scss'

const FooterContainer = styled.div`
  background: ${props => props.backColor ? props.backColor : "#4b4b4b"};
  color: ${props => props.textColor ? props.textColor : "#ffffff"};
`;

export default function Footer() {

    const [data, setData] = useContext(ManifestContext);
    const footerData = data.footer;

    return (
        <FooterContainer className="footer-outer">
            <p>{footerData.address}</p>
            <p>{footerData.contact}</p>
            <p>© {footerData.company}</p>
            <div onClick={()=>{SignOut();}}><u>Sign Out</u></div>
        </FooterContainer>
    )
}
