import React, { useContext, useRef, useEffect } from 'react'
import ClassGen from '../../../../libs/class-gen'
import styled from 'styled-components';
import Button from '../../generic/Button'
import { ResponsiveContext } from 'react-responsive-window'
import useContactForm from "./useContactForm"
import usePubEndpoint from "../../../../global-hooks/usePubEndpoint"
import ReCAPTCHA from 'react-google-recaptcha';
import {useContactLoading} from './useContactLoading'
import LoadingComponent from '../../generic/loading/LoadingComponent'
import './ContactForm.scss'

const FormContainer = styled.div`
    background: ${props => props.backColor ? props.backColor : "#ffffff"};
`;

const TitleContainer = styled.div`
    color: ${props => props.titleColor ? props.titleColor : "#ffffff"};
`;


export default function ContactForm(props) {
    const [responsiveObj] = useContext(ResponsiveContext);

    const recapEl = useRef(null);

    const loading = useContactLoading();
    const contactContext = useContactForm();
    const contact = usePubEndpoint();
    
    let errorBox;

    let sizeStrings = {
        xl: "contact-form-background-lg",
        lg: "contact-form-background",
        md: "contact-form-background-tablet",
        sm: "contact-form-background-mobile",
        xs: "contact-form-background-mobile-md"
    }

    let nameMessage = <p className="contact-form-error">Please ensure a valid name has been entered.</p>
    let emailMessage = <p className="contact-form-error">Please ensure a valid email has been entered.</p>
    let commentMessage = <p className="contact-form-error">Please ensure a valid message has been entered.</p>
    let successMessage = <p className="contact-form-error">Message Sent</p>
    let failMessage = <p className="contact-form-error">Failed! Please try again later.</p>
    
    const checkUserInput = () => {
        let namePass = (contactContext.name !== '' && (/^[a-zA-Z\s]*$/i.test(contactContext.name)));
        let commentPass = (contactContext.comment !== '' && contactContext.comment);
        let emailPass = (contactContext.email !== '' && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/.test(contactContext.email)));
        if (namePass && emailPass && commentPass) sendContact();
        else {contactContext.errorSet(!namePass, !emailPass, !commentPass); contactContext.setSuccess(false)};
    }


    const contactFormClass = ClassGen.screenClassAllPre("contact-form", responsiveObj, sizeStrings)
    

    useEffect(() => {
        onLoadRecaptcha();
    },[])

    const onLoadRecaptcha = () => {
        if (recapEl && recapEl.reset) {
            recapEl.reset();
            contactContext.setToken(null);
        } else if (recapEl && recapEl.current && recapEl.current.reset){
            recapEl.current.reset();
            contactContext.setToken(null);
        }
    }

    const sendContact = async () => {
        loading.setIsSpinning(true);
        const contactApi = props.endpoint;
        const contactReturn = await contact.post({
            method: "POST",
            url: contactApi,
            data: {
                "type": "put",
                "params": {
                    name: contactContext.name,
                    email: contactContext.email,
                    message: contactContext.comment
                },
                token:contactContext.token
            }
        });

        if (contactReturn.status && contactReturn.status === 200) {
            contactContext.setSuccess(true);
            contactContext.setFail(false);
            contactContext.errorSet(false, false, false);
            onLoadRecaptcha();
        } else {
            contactContext.setFail(true);
            contactContext.setSuccess(false);
            contactContext.errorSet(false, false, false);
            onLoadRecaptcha();
        }
        loading.setIsSpinning(false);
    }

    //Pre-Render processing
    if (contactContext.error.nameError || contactContext.error.emailError || contactContext.error.commentError) {
        errorBox = <div>
            {contactContext.error.nameError && nameMessage}
            {contactContext.error.emailError && emailMessage}
            {contactContext.error.commentError && commentMessage}
        </div>
    }

    return (<>
        <LoadingComponent isContextControlled={true} context={loading}/>
        <FormContainer className={contactFormClass} {...props}>

            <div className="contact-form-card">

                <TitleContainer className="contact-form-title"  {...props}>
                    Get In Touch!
                </TitleContainer>

                {errorBox}
                {contactContext.success && successMessage}
                {contactContext.fail && failMessage}

                <div className="contact-form-inputs">
                    <input
                        type="text"
                        className="contact-form-input"
                        placeholder="Name..."
                        onChange={(e) => {contactContext.nameSet(e)}}
                    />

                    <input
                        type="text"
                        className="contact-form-input"
                        placeholder="Email..."
                        onChange={(e) => {contactContext.emailSet(e)}}
                    />

                    <textarea
                        type="text"
                        className="contact-form-input"
                        placeholder="Type your message here..."
                        rows="5"
                        onChange={(e) => {contactContext.commentSet(e)}}
                    />
                </div>
                <div className="contact-form-recap">
                    <ReCAPTCHA
                        ref={recapEl}
                        onExpired={onLoadRecaptcha}
                        onErrored={onLoadRecaptcha}
                        sitekey="6Lf-L8gZAAAAAGx-NDdwZd9qhca87sEjFbctSHOr"
                        onChange={(e)=>{contactContext.verifyCallback(e)}}
                    />
                </div>

                {contactContext.token && <div className="contact-form-buttons">
                    <Button {...props.primary} onClick={()=>{checkUserInput()}}>{props.buttonText}</Button>
                </div>}
            </div>
        </FormContainer>
    </>)
}
