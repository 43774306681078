import {useState, useEffect} from "react";
import axios from "axios";

export default function usePubEndpoint() {

  const post = (req) => {
      return axios(req);
  }

  return {post};
}