import React, {useContext, useState} from 'react'
import styled from 'styled-components';
import {ResponsiveContext} from 'react-responsive-window'
import ClassGen from '../../../../libs/class-gen'
import Button from '../../generic/Button'
import './Hero.scss'

const HeroContainer = styled.div`
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : "15px"};
    margin-top: -1px;
    background: ${props => props.backColor ? props.backColor : "#ffffff"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${props => props.wide ? props.backPosWide : props.backPosSquare};
    background-image: linear-gradient(
        ${props => props.backGrad1 ? props.backGrad1  : "rgba(255,255,255,0)"}, 
        ${props => props.backGrad2 ? props.backGrad2  : "rgba(255,255,255,0)"}
    ), url(${props => props.isWide ? props.backImageWide  : props.backImageSquare});
`;

const HeroDiv = styled.div`
    padding: 20px;
    position: relative;
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: row;
    background: ${props => props.HeroImageContainer ? props.HeroImageContainer : "#000"};
    font-size: ${props => props.small ? '35px' : '65px'};
    color: ${props => props.textColor ? props.textColor : "green"};
    font-weight: 700;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const HeroDivButton = styled.div`
    padding: 10px;
    position: relative;
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    background: ${props => props.buttonContainerColor ? props.buttonContainerColor : "#000"};
    border-radius: 10px;
    margin-bottom: 10px;
`;


export default function Hero(props) {

    const [responsiveObj] = useContext(ResponsiveContext);
    const [wide, setWide] = useState(true);

    //decide on classes for the different heights
    let sizeStrings = {
        xl: "hero-background-lg" + (props.heroMini ? '-mini' : ''),
        lg: "hero-background" + (props.heroMini ? '-mini' : ''),
        md: "hero-background-tablet" + (props.heroMini ? '-mini' : ''),
        sm: "hero-background-mobile" + (props.heroMini ? '-mini' : ''),
        xs: "hero-background-mobile-md" + (props.heroMini ? '-mini' : '')
    }

    const heroContainerClass = ClassGen.screenClassAllPre('hero-container', responsiveObj, sizeStrings)

    if(responsiveObj.mobileXl && wide){
        setWide(false)
    } else if (!responsiveObj.mobileXl && !wide){
        setWide(true)
    }

    const heroButton1 = () => {

    }

    const heroButton2 = () => {
        
    }

    const heroLogoClass = ClassGen.conditioned(responsiveObj.mobileXl, ClassGen.conditioned(responsiveObj.mobileMd, "hero-logo-sm", "hero-logo-md"),  "hero-logo")

    let components = [];

    if (props.isHeroImage){
        components.push(<HeroDiv {...props}  key={0}>
            <img className={heroLogoClass} src={props.HeroImage} alt={props.HeroImageAlt} />
        </HeroDiv>)
    }

    if (props.isHeroTitle){
        components.push(<HeroDiv {...props} small={responsiveObj.mobileXl} key={1}>
            <img className={heroLogoClass} src={props.logo} alt={(props.logoAlt ? props.logoAlt : "logo") }/>
            <p className='hero-title'>{props.heroTitle}</p>
        </HeroDiv>)
    }

    /*if (props.isButton) {
            <Button {...props.primary} onClick={()=>{heroButton1()}}>{props.buttonText}</Button>
            {props.isButton2 && <Button {...props.secondary} onClick={()=>{heroButton2()}}>{props.buttonText2}</Button>}
        </HeroDivButton>)
    }*/

    return (
        <HeroContainer className={heroContainerClass} isWide={wide} {...props}>
            {components.map((component) =>(
                    component
            ))}
            {!components[0] && <h1>Leagues Coming Soon</h1>}
        </HeroContainer>
    )
}