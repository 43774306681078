import React, {useContext} from 'react'
import {ManifestContext} from '../../../../root/entry/App'
import Loading from './Loading';

export default function LoadingComponent({isContextControlled, context}) {

    const [data, setData] = useContext(ManifestContext);

    return (<>
        {isContextControlled && context.isSpinning && <Loading loading={data.loading}/>}
        {!isContextControlled && <Loading loading={data.loading}/>}
    </>)
}
