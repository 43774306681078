import React, {useContext, createContext, useState} from 'react'

const headerLoadingContext = createContext();

export function ProvideHeaderLoading({children}){
    const headerLoading = UseHeaderLoading();
    return <headerLoadingContext.Provider value={headerLoading}>{children}</headerLoadingContext.Provider>
}

export const useHeaderLoading = () => {
    return useContext(headerLoadingContext);
}

export default function UseHeaderLoading() {

    const [isSpinning, setIsSpinning] = useState(null);

    return {
        isSpinning, setIsSpinning
    };
}
