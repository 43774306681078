import React,{useContext} from 'react'
import {ResponsiveContext} from 'react-responsive-window'
import './TextList.scss'

export default function TextList(props) {

    const [responsiveObj] = useContext(ResponsiveContext);

    return (
        <div className={`text-list-item ${responsiveObj.mobileMd ? "text-list-item-mobile" : "" }`}>
            {!props.listInfo.ordered && <ul>
                {props.listInfo.items.map((listItem, index)=>(
                    <li key={index}>{listItem}</li>
                ))}
            </ul>}
            {props.listInfo.ordered && <ol>
                {props.listInfo.items.map((listItem, index)=>(
                    <li key={index}>{listItem}</li>
                ))}
            </ol>}
        </div>
    )
}
